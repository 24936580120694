import { SUB_EVENT_TYPES } from '@/provider/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { prepareSchema } from '@/provider/utils';
import monitoringItemListSchema from '../api/monitoring-item-list.graphql';

export function useMonitoringItems(parentSchemaId) {
  const { subscribeToMore, result, load } = useLazyQuery(
    monitoringItemListSchema.load,
    {
      parentSchemaId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const list = useResult(result, [], data => {
    return data.schemata.map(i => prepareSchema(i));
  });

  subscribeToMore({
    document: monitoringItemListSchema.listenMonitoringItemList,
    variables: {},
    updateQuery: (previousResult, { subscriptionData }) => {
      const relatedNode = subscriptionData.data?.Schemas?.relatedNode;
      const eventType = subscriptionData.data?.Schemas?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'Schema': {
          return {
            schemata: [...previousResult.schemata, relatedNode]
          };
        }
        case 'SchemaProperty': {
          const { schemaId } = relatedNode;
          const schemaIndex = previousResult.schemata.findIndex(
            schema => schema.id === schemaId
          );
          if (schemaIndex > -1) {
            const newObject = { ...previousResult.schemata[schemaIndex] };
            newObject.schemaProperties = [
              ...previousResult.schemata[schemaIndex].schemaProperties,
              relatedNode
            ];
            const copyArray = previousResult.schemata.slice(0);
            copyArray.splice(schemaIndex, 1, newObject);
            return {
              schemata: copyArray
            };
          }
        }
      }
    }
  });

  return {
    load,
    list
  };
}
