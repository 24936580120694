import { usePopup } from '@/compositions/popup';
import { schemasService } from '@/modules/common/api';
import { genMenuItemEdit, genMenuItemDelete } from '@/utils/list-generators';

export function useStateItemMenu() {
  const popup = usePopup();

  return {
    genMenu: (state, canDelete = true) => {
      const { id, typeId, parentType } = state;
      const items = [
        genMenuItemEdit(() => {
          popup.open({
            component: () =>
              import(
                '@/modules/object-types/ui/type-card/general/StateEdit.vue'
              ),
            props: {
              stateId: id,
              parentSchemaId: typeId,
              parentType
            }
          });
        })
      ];
      if (canDelete) {
        items.push(
          genMenuItemDelete(() => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Delete state?',
                onConfirm: () => schemasService.delete(id)
              }
            });
          })
        );
      }
      return items;
    }
  };
}
