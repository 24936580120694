import { usePopup } from '@/compositions/popup';
import { schemasService } from '@/modules/common/api';
import { genMenuItemEdit, genMenuItemDelete } from '@/utils/list-generators';

export function usePropertyItemMenu() {
  const popup = usePopup();

  return {
    genMenu: propertyItem => {
      const { id, typeId } = propertyItem;
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () =>
              import(
                '@/modules/object-types/ui/type-card/general/PropertyEdit.vue'
              ),
            props: {
              propertyId: id,
              schemaId: typeId
            }
          });
        }),
        genMenuItemDelete(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Delete property?',
              onConfirm: () => schemasService.deleteProperty(id)
            }
          });
        })
      ];
    }
  };
}
