import { usePopup } from '@/compositions/popup';
import { schemasService } from '@/modules/common/api';
import { genMenuItemEdit, genMenuItemDelete } from '@/utils/list-generators';
import { genMenuItemDefault } from '@/utils/list-generators/menu';

export function useMonitoringItemMenu() {
  const popup = usePopup();

  return {
    genMenu: monitoringItem => {
      const { id, typeId } = monitoringItem;
      const items = [
        genMenuItemEdit(() => {
          popup.open({
            component: () =>
              import(
                '@/modules/object-types/ui/type-card/general/MonitoringItemEdit.vue'
              ),
            props: {
              monitoringItemId: id,
              parentSchemaId: typeId
            }
          });
        }),
        genMenuItemDefault(
          {
            id: 'alarms',
            icon: '$alert',
            title: 'Alarms'
          },
          {
            click: () => {
              popup.open({
                component: () =>
                  import('./MonitoringItemAlarmsDefaultEdit.vue'),
                props: {
                  monitoringItemId: id
                }
              });
            }
          }
        ),
        genMenuItemDelete(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Delete monitoring item?',
              onConfirm: () => schemasService.delete(id)
            }
          });
        })
      ];
      return items;
    }
  };
}
